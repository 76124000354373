<template>
  <section
    id="main-desc"
    style="background-color: #e8f1f2"
  >
  <br>
  <br>
  <br>
    <base-left-photo
      :src="require('@/assets/nitrile.jpg')"
      :subheading="subheading1"
      :html="nitrile"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/surgical.jpg')"
      :subheading="subheading4"
      :html="surgical"
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/household.jpg')"
      :subheading="subheading5"
      :html="household"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/cleanroom.jpg')"
      :subheading="subheading6"
      :html="cleanroom"
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/latex.jpg')"
      :subheading="subheading3"
      :html="latex"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/industrial.jpg')"
      :subheading="subheading2"
      :html="industrial"
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/safety.jpg')"
      :subheading="subheading7"
      :html="safety"
      color="black--text"
    >
    </base-left-photo>
    <v-row
      class="px-0 mx-0"
    >
      <v-col
        cols="12"
        sm="6"
        class="px-0 mx-0"
        style="background-color: #e8f1f2"
      >
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="90%"
        >
          <base-subheading
            weight="regular"
            :title="subheading8"
            class="text-center font-weight-bold"
          />
          <div
            class="mx-10"
          >
            {{chemo}}
          </div>
        </v-responsive>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="px-0 py-0 mx-0"
      >
        <v-img
          :src="require('@/assets/chemotherapy.jpg')"
          :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
        >
        </v-img>
      </v-col>
    </v-row>
    <br>
    <br>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainDesc',
    computed: {
      subheading1 () {
        return this.$t('main-desc.subheading1')
      },
      subheading2 () {
        return this.$t('main-desc.subheading2')
      },
      subheading3 () {
        return this.$t('main-desc.subheading3')
      },
      subheading4 () {
        return this.$t('main-desc.subheading4')
      },
      subheading5 () {
        return this.$t('main-desc.subheading5')
      },
      subheading6 () {
        return this.$t('main-desc.subheading6')
      },
      subheading7 () {
        return this.$t('main-desc.subheading7')
      },
      subheading8 () {
        return this.$t('main-desc.subheading8')
      },
      nitrile () {
        return this.$t('main-desc.nitrile')
      },
      surgical () {
        return this.$t('main-desc.surgical')
      },
      household () {
        return this.$t('main-desc.household')
      },
      cleanroom () {
        return this.$t('main-desc.cleanroom')
      },
      latex () {
        return this.$t('main-desc.latex')
      },
      industrial () {
        return this.$t('main-desc.industrial')
      },
      safety () {
        return this.$t('main-desc.safety')
      },
      chemo () {
        return this.$t('main-desc.chemo')
      },
    },
  }
</script>
